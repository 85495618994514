import KauflandTrackingContainer from 'containers/KauflandTrackingContainer';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function KauflandTrackingProvider() {
  const navigate = useNavigate();
  const location = useLocation();
  const [prevLocationPathname, setPrevLocationPathname] = useState(location.pathname);
  const [hasRemovedParams, setHasRemovedParams] = useState(false);

  useEffect(() => {
    if (!location.search || hasRemovedParams) {
      return;
    }
    if (prevLocationPathname !== location.pathname) {
      setHasRemovedParams(true);
      setPrevLocationPathname(location.pathname);
      navigate(location.pathname, { replace: true });
    }
  }, [hasRemovedParams, location, navigate, prevLocationPathname]);

  return <KauflandTrackingContainer />;
}
