import { useFlippyPageNumber } from 'helper/js/hooks/router';
import { useSelector } from 'react-redux';

import { getVisiblePagesAmount } from '../../helper';

const PAGE_TYPE = 'Leaflet Detail';
const FEATURE_TYPE = 'Leaflet';

function useKauflandTrackingDataLayer(): DigitalData {
  const countryCode = useSelector((state: AppState) => state.appData.countryCode);
  const flyerId = useSelector((state: AppState) => state.appData.id);
  const locale = useSelector((state: AppState) => state.appData.locale);
  const flippyPageNumber = useFlippyPageNumber();
  const title = useSelector((state: AppState) => state.appData.title);
  const internalName = useSelector((state: AppState) => state.appData.name);
  const startDate = useSelector((state: AppState) => state.appData.startDate);
  const endDate = useSelector((state: AppState) => state.appData.endDate);
  const homeUrl = useSelector((state: AppState) => state.appData.urls.homeUrl);
  const pages = useSelector((state: AppState) => state.appData.pages) as APagesItem[];
  const totalPages = useSelector((state: AppState) => state.appData.pages.length);
  const subcategory = useSelector((state: AppState) => state.appData.subcategory);
  const regionCodes = useSelector((state: AppState) => state.appData.regionCodes);
  const environment = import.meta.env.VITE_CUSTOM_ENV;
  const isWideScreen = useSelector((state: AppState) => state.browser.isWideScreen);

  function getCurrentPages(): PageItem[] {
    let currentPages: APagesItem[] = [];
    const nextVisiblePages = getVisiblePagesAmount(flippyPageNumber, totalPages);

    if (flippyPageNumber > totalPages) {
      return [];
    }

    if (!isWideScreen) {
      currentPages = [pages[flippyPageNumber - 1]];
    } else {
      currentPages =
        nextVisiblePages === 2
          ? pages.slice(flippyPageNumber - 1, flippyPageNumber + 1)
          : [pages[flippyPageNumber - 1]];
    }

    return currentPages.map(
      ({ id, number, type, pageType, recommendedProductIds, links, keyWords }) => ({
        id,
        number,
        type,
        pageType,
        recommendedProductIds,
        links,
        keywords: keyWords,
      }),
    );
  }

  const digitalData: DigitalData = {
    page: {
      pageInfo: {
        pageName: `kl:${countryCode}:${locale.substring(
          0,
          2,
        )}:leaflet:leaflet detail:hyper1:${title}:valid from ${startDate} to ${endDate}:page ${flippyPageNumber}`.toLowerCase(),
        sysEnv: environment,
      },
      category: {
        featureType: FEATURE_TYPE,
        pageType: PAGE_TYPE,
      },
      attributes: {
        country: countryCode,
        language: locale,
      },
    },
    leafletInfo: {
      pages: getCurrentPages(),
      totalPages,
      id: flyerId,
      leafletName: title,
      leafletType: subcategory,
      validDate: `Valid from ${startDate} to ${endDate}`,
      homeUrl,
      name: internalName,
      stores: regionCodes.map((regionCode) => `${countryCode}${regionCode}`),
    },
  };
  return digitalData;
}
export default useKauflandTrackingDataLayer;
