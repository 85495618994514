import ButtonC, { BUTTON_APPEARANCE } from 'components/ButtonC';
import { LINK_TARGET } from 'components/Link';

import styles from './ErrorKaufland.module.scss';

interface ErrorKauflandProps {
  title: string;
  subTitle: string;
  quitUrl?: string;
  quitUrlTitle: string;
  handleButtonClick: () => void;
}

export default function ErrorKaufland({
  title,
  subTitle,
  quitUrl,
  quitUrlTitle,
  handleButtonClick,
}: Readonly<ErrorKauflandProps>): JSX.Element {
  return (
    <div className={styles.error}>
      <h2 className={styles.headline}>{title}</h2>
      <span className={styles.text} dangerouslySetInnerHTML={{ __html: subTitle }} />
      {quitUrl && (
        <div className={styles.button}>
          <ButtonC
            label={quitUrlTitle}
            href={quitUrl}
            type={BUTTON_APPEARANCE.PRIMARY}
            onClick={handleButtonClick}
            target={LINK_TARGET.PARENT}
          />
        </div>
      )}
    </div>
  );
}
