import { CUSTOM_EVENTS } from 'helper/js/constants';
import useEventListener from 'helper/js/hooks/use-event-listener';
import useKauflandTrackingDataLayer from 'helper/js/hooks/use-kaufland-tracking-data-layer';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const ADOBE_EVENT = {
  PAGE_VIEW: 'mke',
  LINK_CLICKED: 'exit link',
};

export default function KauflandTrackingContainer(): null {
  const [hasLoaded, setHasLoaded] = useState(false);
  const kauflandTrackingDataLayer = useKauflandTrackingDataLayer();
  const isDebugTrackingEnabled = useSelector((state: AppState) => state.settings.debugTracking);
  const isNavigating = useSelector((state: AppState) => state.sheet.navigate);

  useEffect(() => {
    const scriptAdobe = document.createElement('script');
    if (import.meta.env.VITE_ADOBE_ANALYTICS_URL) {
      scriptAdobe.src = import.meta.env.VITE_ADOBE_ANALYTICS_URL;
      scriptAdobe.async = true;
      document.head.appendChild(scriptAdobe);
      scriptAdobe.onload = () => setHasLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (isNavigating) {
      return;
    }
    if (hasLoaded) {
      window.digitalData = kauflandTrackingDataLayer;
      if (window._satellite) {
        window._satellite.track(ADOBE_EVENT.PAGE_VIEW);
      }
    }
    if (isDebugTrackingEnabled) {
      console.log('Kaufland Tracking', ADOBE_EVENT.PAGE_VIEW, kauflandTrackingDataLayer);
    }
  }, [kauflandTrackingDataLayer, hasLoaded, isDebugTrackingEnabled, isNavigating]);

  useEventListener(CUSTOM_EVENTS.TRACK_EXTERNAL_LINK_CLICK, (eventData) => {
    if (!eventData?.detail?.link || !eventData?.detail?.id) {
      return;
    }

    const { link, id } = eventData.detail;
    const url = new URL(link);
    const digitalData: DigitalData = {
      ...kauflandTrackingDataLayer,
      leafletInfo: {
        ...kauflandTrackingDataLayer.leafletInfo,
        pages: [
          ...kauflandTrackingDataLayer.leafletInfo.pages
            .filter((page) => page.links.some((pageLink) => pageLink.id === id))
            .map((page) => {
              const clickedLink = page.links.filter((pageLink) => pageLink.id === id);
              return {
                ...page,
                links: clickedLink,
              };
            }),
        ],
      },
    };

    const payload = {
      conversionType: 'Exit Link',
      conversionDetail: url.hostname + url.pathname,
      conversionSubDetail1: url.hostname,
      conversionSubDetail2: url.pathname.substring(1),
      digitalData,
    };

    if (hasLoaded && window._satellite) {
      window._satellite.track(ADOBE_EVENT.LINK_CLICKED, payload);
    }

    if (isDebugTrackingEnabled) {
      console.log('Kaufland Tracking', ADOBE_EVENT.LINK_CLICKED, payload);
    }
  });

  return null;
}
